* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", Arial, sans-serif;
}

:root {
  --blue: #203140;
  --white: #fafdff;
  --red: #8c0f0f;
  --lightRed: #c91212;
}

body,
input,
textarea,
button {
  font: 400 1.25rem "Roboto", Arial, sans-serif;
  color: var(--white);
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 14px;
}

.page {
  min-height: 100vh;
  max-width: 100%;

  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;

  background: url(../images/condominio.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.page::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--blue);
  opacity: 0.05;
  z-index: -1;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.container .containerInfo {
  height: 100vh;
  width: 70%;
  display: flex;
}

.container .containerForm {
  height: 100vh;
  width: 40%;
  display: flex;
}

.container .containerForm Form {
  left: 0;
}

/* -- RESPONSIVE -- */
@media (min-width: 1600px) {
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    font-size: 16px;
  }
}

@media (max-width: 1025px) {
  .page {
    background-image: none;
  }

  .container {
    flex-direction: column;
  }

  .page .container .containerInfo {
    height: 80vh;
    width: 100%;
    align-items: center;
    background-image: url(../images/condominio.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  .page .container .containerInfo::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--blue);
    opacity: 0.15;
    z-index: -1;
  }

  .container .containerForm {
    height: 70vh;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .container .containerForm Form {
    left: 0;
  }
}

@media (max-width: 670px) {
  .container .containerForm {
    height: 90vh;
  }
}

@media (max-width: 469px) {
  .page .container .containerInfo {
    height: 100vh;
  }
  .container .containerForm {
    height: 90vh;
  }
}

@media (max-width: 325px) {
  .page .container .containerInfo {
    height: 100vh;
  }
  .container .containerForm {
    height: 100vh;
  }
}
/* -- RESPONSIVE -- */
