.section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  width: 450px;
  height: 100%;
  padding-left: 10%;
}

.section .title {
  display: flex;
  align-items: center;
  position: relative;

  height: 100px;
  margin-bottom: 120px;
  top: 30px;

  left: 0;
}

.section .title img {
  width: 400px;
  height: 200px;
}

.section .container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;

  width: 380px;
  height: 250px;
  left: 0;
}

.section .container .mapCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 70px;
  padding: 2px;

  border-radius: 5px;
  background: var(--white);
}

.section .container .mapCard .text p {
  line-height: 125%;
  font-size: 16px;
  font-weight: 400;

  color: #000;
}

.section .container .mapCard .icon {
  display: relative;

  padding: 0 15px;
}

.section .container .card {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 60px;
  padding: 2px;

  border-radius: 5px;
  background: var(--white);
}

.section .container .card .icon {
  display: relative;

  padding: 0 10px;
}

.section .container .card .text p {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
}

.section .container .card .mailText p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
}

/* -- RESPONSIVE -- */
@media (min-width: 1600px) {
  .section {
    padding-left: 7%;
  }

  .section .title {
    top: 100px;

    margin-bottom: 200px;
  }

  .section .title img {
    width: 500px;
    height: 300px;
  }

  .section .container {
    width: 450px;
    height: 400px;
  }

  .section .container .mapCard {
    height: 80px;
  }

  .section .container .mapCard .text p {
    font-size: 19px;
  }

  .section .container .card {
    height: 80px;
  }

  .section .container .card .text p {
    font-size: 19px;
  }

  .section .container .card .mailText p {
    font-size: 19px;
  }
}

@media (max-width: 1025px) {
  .section {
    width: 100%;
    left: 0 !important;
    padding-left: 0 !important;
    align-items: center;
  }

  .section .title img {
    width: 500px;
    height: 350px;
  }

  .section .container {
    width: 450px;
    height: 400px;
    margin-top: 100px;
  }

  .section .container .mapCard {
    height: 100px;
  }

  .section .container .mapCard .text p {
    font-size: 18px;
  }
  .section .container .card .text p {
    font-size: 18px;
  }

  .section .container .card .mailText p {
    font-size: 18px;
  }

  .section .container .mapCard .icon {
    padding-right: 30px;
  }

  .section .container .card .icon {
    padding-right: 30px;
  }

  .section .container .card {
    height: 100px;
  }
}

@media (max-width: 785px) {
  .section {
    width: 100%;
    left: 0 !important;
    padding-left: 0 !important;
    align-items: center;
  }

  .section .title {
    width: 400px;
    top: 100px !important;
    margin-bottom: 100px;
    left: -20px;
  }

  .section .title img {
    width: 450px;
    height: 255px;
  }

  .section .container {
    width: 400px;
    height: 350px;
  }

  .section .container .mapCard {
    height: 80px;
  }
  .section .container .card {
    height: 80px;
  }

  .section .container .mapCard .icon {
    padding-right: 15px;
  }

  .section .container .card .icon {
    padding-right: 10px;
  }
}

@media (max-width: 550px) {
  .section {
    width: 100%;
    padding-left: 4% !important;
    left: 0;
    align-items: flex-start;
  }

  .section .title {
    top: 45px !important;
    left: 20px !important;
    width: 300px;
  }

  .section .title img {
    width: 350px;
    height: 175px;
  }

  .section .container {
    width: 340px;
    margin-top: 45px;
  }

  .section .container .mapCard {
    width: 100%;
  }

  .section .container .mapCard .text p {
    font-size: 14px;
  }

  .section .container .card {
    width: 100%;
  }

  .section .container .card .text p {
    font-size: 14px;
  }

  .section .container .card .mailText p {
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  .section {
    left: 0 !important;
    padding-left: 0 !important;
  }

  .section .title {
    top: 40px !important;
    left: 0;
  }

  .section .title img {
    width: 300px;
    height: 150px;
  }

  .section .container {
    width: 340px;
    margin-top: 0;
    padding: 0;
  }

  .section .container .mapCard {
    width: 90%;
  }

  .section .container .mapCard .text p {
    font-size: 12px;
  }

  .section .container .card {
    width: 90%;
  }

  .section .container .card .text p {
    font-size: 12px;
  }

  .section .container .card .mailText p {
    font-size: 12px;
  }
}

@media (max-width: 380px) {
  .section .container {
    left: 2.5%;
  }
}

@media (max-width: 325px) {
  .section {
    width: 100%;
    padding-left: 0 !important;
    left: 0 !important;
  }

  .section .title {
    top: 40px !important;
    left: 0;
  }

  .section .title img {
    width: 270px;
    height: 180px;
  }

  .section .container {
    width: 320px;
    margin-top: 0;
    padding: 0;
  }

  .section .container .mapCard {
    width: 90%;
  }

  .section .container .mapCard .text p {
    font-size: 12px;
  }

  .section .container .card {
    width: 90%;
  }

  .section .container .card .text p {
    font-size: 12px;
  }

  .section .container .card .mailText p {
    font-size: 12px;
  }
}
/* -- RESPONSIVE -- */
