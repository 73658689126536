.container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  background-color: var(--blue);
  z-index: 1;
}

/* form and title (content) */
.container .formContainer {
  display: flex-start;
  position: relative;
  align-items: center;
  margin-top: 10px;
  width: 350px;
  padding-left: 5%;
}

.container .formContainer .title {
  display: flex;
  position: relative;
  align-items: center;

  width: 300px;
  margin-bottom: 10px;
}

.container .formContainer .title h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  letter-spacing: 1px;
  line-height: 120%;

  color: var(--white);
}

.container .formContainer form fieldset {
  border: none;
  width: 300px;
  align-items: center;
}

.container .formContainer form {
  width: 300px;
}

.container .formContainer form .formGroup {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 300px;

  margin-bottom: 5px;
}

.container .formContainer form .formGroup label {
  display: block;

  font-size: 16px;

  margin: 4px 0;
  left: 0;
  float: left;
}

.container .formContainer form .formGroup input {
  width: 300px;
  height: 20px;
  padding: 15px 0 15px 10px;

  background: rgba(96, 113, 128, 0.05);
  border: 1px solid var(--white);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
}

.container .formContainer form .formGroupMessage {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 300px;

  margin-bottom: 10px;
}

.container .formContainer fieldset .formGroupMessage label {
  display: block;

  font-size: 16px;

  margin: 5px 0;
  left: 0;
  float: left;
}

.container .formContainer form .formGroupMessage textarea {
  width: 300px;
  height: 80px;

  padding: 10px 10px 15px 10px;

  font-size: 14px;
  resize: none;
  box-sizing: border-box;
  border-radius: 5px;
  background: rgba(96, 113, 128, 0.05);
  border: 1px solid var(--white);
}

.container .formContainer form fieldset button {
  width: 300px;
  height: 30px;

  margin-top: 10px;

  font-style: normal;
  font-weight: 700;
  font-size: 15px;

  border: none;
  border-radius: 5px;
  color: var(--blue);
  background: var(--white);

  transition: 0.2s;
}

.container .formContainer form fieldset button:hover {
  background-color: var(--lightRed);
  color: var(--white);
}

.g-recaptcha {
  transform-origin: left top;
  -webkit-transform-origin: left top;
}

/* -- RESPONSIVE -- */
@media (min-width: 1600px) {
  .container .formContainer {
    width: 400px;
  }

  .container .formContainer .title {
    width: 350px;
  }

  .container .formContainer .title h1 {
    font-size: 38px;
  }

  .container .formContainer form fieldset {
    width: 350px;
  }

  .container .formContainer form {
    width: 350px;
  }

  .container .formContainer form .formGroup {
    width: 350px;
    margin-bottom: 15px;
  }

  .container .formContainer form .formGroup label {
    font-size: 20px;
    margin: 6px 0;
  }

  .container .formContainer form .formGroup input {
    height: 45px;
    width: 350px;
    font-size: 16px;
  }

  .container .formContainer form .formGroupMessage {
    width: 350px;
    margin-bottom: 15px;
  }

  .container .formContainer form .formGroupMessage label {
    font-size: 20px;
    margin: 8px 0;
  }

  .container .formContainer form .formGroupMessage textarea {
    height: 120px;
    width: 350px;
    font-size: 16px;
  }

  .container .formContainer form fieldset button {
    width: 350px;
    height: 40px;
    font-size: 16px;
    margin-top: 15px;
  }
}

@media (max-width: 1025px) {
  .container .formContainer {
    width: 400px;
    padding-left: 0;
  }

  .container .formContainer .title {
    width: 390px;
  }

  .container .formContainer .title h1 {
    font-size: 38px;
  }

  .container .formContainer form fieldset {
    width: 350px;
  }

  .container .formContainer form {
    width: 350px;
  }

  .container .formContainer form .formGroup {
    width: 350px;
    margin-bottom: 15px;
  }

  .container .formContainer form .formGroup label {
    font-size: 20px;
    margin: 6px 0;
  }

  .container .formContainer form .formGroup input {
    height: 45px;
    width: 350px;
    font-size: 16px;
  }

  .container .formContainer form .formGroupMessage {
    width: 350px;
    margin-bottom: 15px;
  }

  .container .formContainer form .formGroupMessage label {
    font-size: 20px;
    margin: 8px 0;
  }

  .container .formContainer form .formGroupMessage textarea {
    height: 120px;
    width: 350px;
    font-size: 16px;
  }

  .container .formContainer form fieldset button {
    width: 350px;
    height: 40px;
    font-size: 16px;
    margin-top: 15px;
  }
}

@media (max-width: 540px) {
  .container .formContainer {
    width: 300px;
    padding-left: 0;
  }

  .container .formContainer .title {
    width: 300px;
  }

  .container .formContainer .title h1 {
    font-size: 30px;
  }

  .container .formContainer form fieldset {
    width: 300px;
  }

  .container .formContainer form {
    width: 300px;
  }

  .container .formContainer form .formGroup {
    width: 300px;
    margin-bottom: 15px;
  }

  .container .formContainer form .formGroup label {
    font-size: 18px;
    margin: 4px 0;
  }

  .container .formContainer form .formGroup input {
    width: 300px;
    height: 25px;
    font-size: 16px;
  }

  .container .formContainer form .formGroupMessage {
    width: 300px;
    margin-bottom: 10px;
  }

  .container .formContainer form .formGroupMessage label {
    font-size: 18px;
    margin: 5px 0;
  }

  .container .formContainer form .formGroupMessage textarea {
    height: 90px;
    width: 300px;
    font-size: 16px;
  }

  .container .formContainer form fieldset button {
    width: 300px;
    height: 35px;
    font-size: 15px;
    margin-top: 10px;
  }
}

@media (max-width: 469px) {
  .container {
    padding-left: 0;
  }

  .container .formContainer {
    width: 300px;
  }
  .container .formContainer form fieldset {
    width: 300px;
  }
}
/* -- RESPONSIVE -- */
