.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.7);
}

.modalContent {
  width: 400px;

  background-color: var(--white);
  color: #000;

  border-radius: 5px;

  animation: animacao 1.5s;
}

.modalHeader,
.modalFooter {
  padding: 10px;
}

.modalHeader {
  margin: 10px;

  display: flex;
  justify-content: flex-end;
}

.modalHeader button {
  border: none;
  background: none;
}

.modalHeader img {
  padding: 5px;
}

.modalBody {
  padding: 0 10px 15px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalBody h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 500;
}

.modalBody h2 {
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalFooter button {
  width: 130px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 35px;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  background: var(--blue);
  color: var(--white);
  border: none;
  border-radius: 5px;

  transition: 0.2s;
}

.modalFooter button:hover {
  background-color: var(--red);
}

@keyframes animacao {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

/* RESPONSIVE */
@media (min-width: 1600px) {
  .modalContent {
    width: 500px;
  }

  .modalHeader,
  .modalFooter {
    padding: 10px;
  }

  .modalHeader img {
    padding: 5px;
  }

  .modalBody h1 {
    font-size: 38px;
    line-height: 45px;
  }

  .modalBody h2 {
    font-size: 22px;
    line-height: 26px;
  }

  .modalFooter button {
    width: 143px;
    height: 42px;
    margin-top: 20px;
    margin-bottom: 35px;

    font-size: 20px;
    line-height: 23px;
  }
}

@media (max-width: 670px) {
  .modalContent {
    width: 95%;
  }
}

@media (max-width: 570px) {
  .modalBody h1 {
    font-size: 32px;
    line-height: 45px;
  }

  .modalBody h2 {
    font-size: 20px;
    line-height: 16px;
  }
}

@media (max-width: 469px) {
  .modalBody h1 {
    font-size: 30px;
    line-height: 45px;
  }

  .modalBody h2 {
    font-size: 18px;
    line-height: 16px;
  }
}
/* END RESPONSIVE */
